import Cookies from "universal-cookie";
import config from '../../../../config/default';

export const validateUrlParameters = (queryString: URLSearchParams) => {

  const redirectURL = queryString.get("url");
  const rules = queryString.get("rules");

  if (!redirectURL) {
    throw new Error("Ausência de Parâmetros na Url - link de Redirecionamento (url off)");
  }

  if (!rules) {
    throw new Error("Ausência de Parâmetros na Url - regras do projeto (rules off)");
  }

  return { redirectURL, rules };
};

export const setCookiesRefreshToken = (RefreshToken: string) => {
  const cookies = new Cookies();
  cookies.set("refreshToken", RefreshToken, {
    path: "/",
    domain: config.domain,
  });
};

export const setCookiesAccessToken = (AccessToken: string) => {
  const cookies = new Cookies();
  cookies.set("accessToken", AccessToken, {
    path: "/",
    domain: config.domain,
  });
};

export const checkTokenInParams = (): boolean => {
  const url = window.location.href;

  const hashIndex = url.indexOf('#');
  if (hashIndex === -1) return false;

  const hash = url.slice(hashIndex + 1);

  const params = new URLSearchParams(hash.split('?')[1]);

  return params.has('token');
};

export const getRulesFromUrl = (): string | null => {
  const url = window.location.href;

  const hashIndex = url.indexOf('#');
  if (hashIndex === -1) return null;

  const hash = url.slice(hashIndex + 1);

  const params = new URLSearchParams(hash.split('?')[1]);

  return params.get('rules');
};

export const getTokenDecodedFromUrl = (): string | null => {

  const url = window.location.href;

  const hashIndex = url.indexOf('#');
  if (hashIndex === -1) return null;

  const hash = url.slice(hashIndex + 1);

  const params = new URLSearchParams(hash.split('?')[1]);

  const token = params.get('token');
  
  if (!token) return null;

  try {
    return atob(token);
  } catch (error) {
    console.error("Erro ao decodificar o token: ", error);
    return null;
  }
};
import { ILoginFormValues } from "./interface";
import { Buffer } from "buffer";

const urlString = window.location.href; // NOTE: Pega o Url inteira, EX: http://localhost:3001/?url=http://localhost:3000/#/dashboard&rules=supply-chain

const paramString = urlString.split("?")[1]; // NOTE: Pega apenas a url Parametro, Ex: url=http://localhost:3000/#/dashboard&rules=supply-chain

const queryString = new URLSearchParams(paramString);

export const encodeToken = (email: string, password: string): string => {
  const rawToken = `${email} ${password}`;
  return Buffer.from(rawToken).toString("base64");
};

export const updateTokenInUrl = (newToken: string): void => {
  const urlString = window.location.href;
  const hashIndex = urlString.indexOf("#");
  const baseUrl = urlString.substring(0, hashIndex);
  const hashString = urlString.substring(hashIndex + 1);

  const [path, paramsString] = hashString.split("?");
  const queryString = new URLSearchParams(paramsString);

  queryString.set("token", newToken);

  const newParams = Array.from(queryString.entries())
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  const newUrl = `${baseUrl}#${path}?${newParams}`;

  window.history.replaceState({}, "", newUrl);
};

const [initialEmail, initialPassword] = (() => {
  const rawToken = queryString.get("token");
  if (rawToken) {
    const normalizedToken = rawToken.replace(/ /g, "+");
    const decodedString = Buffer.from(normalizedToken, "base64").toString(
      "utf-8"
    );
    return decodedString.split(" ") as [string, string];
  }
  return ["", ""];
})();

export const initialValuesLoginForm: ILoginFormValues = {
  email: initialEmail,
  password: initialPassword,
  rules: "",
};

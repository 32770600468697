import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { Form, Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";

import { Grid, IconButton } from "@mui/material";
import { ArrowBackIos} from "@mui/icons-material";

import { StyledGridContainer } from "../../../components/StyledLayout";
import { StyledCard } from "../../../components/StyledCard";

import { IAuthService } from "../../../modules/user/models/IAuthService";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import { routesConfig } from "../../../config/routes";
import { Types } from "../../../ioc/types";

import { getSnackbar } from "../../../utils/Snackbars";
import { useContextualNavigate } from "../../../hooks/ContextualNavigations";

import { validationSchemaUpdatePassword } from "./form/validationSchema";
import { initialValuesFormikUpdatePassword } from "./form/initialValues";

import SucessPasswordMessage from "./sheet/SuccessPasswordMessage";
import FirstAccessPassword from "./sheet/FirstAccessPassword";
import { FormikValuesUpdatePassword } from "./form/interfaceValues";
import { encodeToken, updateTokenInUrl } from "../../Login/LoginDefault/form/initialValues";

export const UpdatePasswordDefault: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useContextualNavigate();
  
  const location = useLocation();
  const oldPassword = location?.state?.password || "";
  const email = location?.state?.email || "";

  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(Types.User.IAuthService);

  const handleClickGoToBackHome = () => {
    navigate(`${routesConfig.LOGIN}`);
  };

  const [successUpdatePassword, setSuccessUpdatePassword] = useState(false);

  const handleSubmit = async (
    values: FormikValuesUpdatePassword, 
    actions: FormikHelpers<FormikValuesUpdatePassword>
  ) => {
    try {

      if (oldPassword === values.newPass) {
        throw new Error(
          "A senha atual não pode ser igual a senha anterior!"
        );
      }

      const payload = {
        email: values.email,
        old_password: oldPassword,
        new_password: values.newPass,
      };

      await authService.updatePassword(payload);
      const newToken = encodeToken(values.email, values.newPass);
      updateTokenInUrl(newToken);   
      actions.resetForm();
      setSuccessUpdatePassword(true);

      enqueueSnackbar(
        getSnackbar({
          message: "Senha atualizada com sucesso",
          variant: "success",
        })
      );
    } catch (error) {
      setSuccessUpdatePassword(false);

      if ((error?.message).toString().indexOf("not found") !== -1) {
        error.message = "Error: Usuario nao encontrado";
      }

      enqueueSnackbar(
        getSnackbar({
          message: error.message,
          variant: "error",
        })
      );
    }
  }

  return (
    <StyledGridContainer className="UpdatePasswordDefault">
      <StyledCard>
        <Formik
          validateOnMount
          initialValues={{...initialValuesFormikUpdatePassword, email}}
          validationSchema={validationSchemaUpdatePassword}
          onSubmit={handleSubmit}
        >
          {(propsFormik) => (
            <Form>
              <Grid container>
                <Grid item>
                  <IconButton
                    onClick={handleClickGoToBackHome}
                    edge="end"
                    sx={{ display: "flex" }}
                  >
                    <ArrowBackIos sx={{ color: "primary.main", opacity: "1" }} />
                  </IconButton>
                </Grid>
                <Grid item>
                  {successUpdatePassword ? 
                    (<SucessPasswordMessage onClick={handleClickGoToBackHome} isSubmitting={propsFormik.isSubmitting} />) 
                    : ( <FirstAccessPassword {...propsFormik} />)
                  }
                </Grid>
              </Grid>              
            </Form>
          )}
        </Formik>
      </StyledCard>
    </StyledGridContainer>
  );
};

export default UpdatePasswordDefault;

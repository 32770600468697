export interface IAuthService {
  login(data: LoginAuthService): Promise<LoginAuthServiceResponse>;
}

export interface TokenDecoded {
  sub: string;
  auth_time: number;
  exp: number;
  iat: number;
  username: string;
}

export interface LoginAuthService {
  email: string;
  password: string;
  rules: string;
}

export interface IAuthLoginDTO {
  email: string;
  password: string;
  mfaToken?: string;
}

export enum AuthState {
  SENT = "SENT",
  FAILED_SENT = "FAILED_SENT",
  EXHAUSTED_ATTEMPTS = "EXHAUSTED_ATTEMPTS",
  MUST_START_CHALLENGE = "MUST_START_CHALLENGE",
  INCORRECT_TOKEN = "INCORRECT_TOKEN",
  TOO_MANY_ACCESSES = "TOO_MANY_ACCESSES",
  FIRST_ACCESS = "FIRST_ACCESS",
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
}

export interface LoginAuthServiceResponse {
  AccessToken: string; // Necessario que a primeira letra seja maiuscula [default]
  ExpiresIn: number; // Necessario que a primeira letra seja maiuscula [default]
  RefreshToken: string; // Necessario que a primeira letra seja maiuscula [default]
  decodeAccessToken: TokenDecoded;
  isLoginyQrCode: boolean;
  extendedPassword?: string;
  meta: {
    email: string;
    name: string;
    custom_attributes: {
      userStatus: string;
    };
    groups: [];
  };
}
export interface ForgetPasswordAuthService {
  email: string;
  metadata?: Record<string, string>;
}

export interface ChangePasswordAuthService {
  email: string;
  password: string;
  confirmation_code: string;
}

export interface RegisterUserService {
  name: string;
  email: string;
  password: string;
  role: string;
}

export interface UpdatePasswordAuthService {
  email: string;
  old_password: string;
  new_password: string;
}

export interface IAuthLoginResponse {
  state: AuthState;
  AccessToken: string;
  ExpiresIn: number;
  RefreshToken: string;
  isLoginyQrCode: boolean;
  extendedPassword?: string;
  meta: {
    email: string;
    name: string;
    custom_attributes: {
      userStatus: string;
    };
    groups: string[];
    roles: string[];
  };
}
export interface IAuthService {
  authLogin(data: IAuthLoginDTO): Promise<IAuthLoginResponse>;
  login(data: LoginAuthService): Promise<LoginAuthServiceResponse>;
  forgetPassword(data: ForgetPasswordAuthService): Promise<void>;
  changePassword(data: ChangePasswordAuthService): Promise<void>;
  updatePassword(data: UpdatePasswordAuthService): Promise<void>;
  register(data: RegisterUserService): Promise<void>;
}

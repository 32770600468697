import { createContext, useContext, useEffect } from 'react';

import { AuthState, IAuthLoginResponse, IAuthService } from '../../../../modules/user/models/IAuthService';
import { UserStatus } from '../../../../modules/user/domain/constants';

import { useContextualNavigate } from '../../../../hooks/ContextualNavigations';
import useDialogAlert from '../../../../hooks/useDialogAlert';

import { routesConfig } from '../../../../config/routes';
import AppError from '../../../../utils/appError';

import { useIocContext } from '../../../../contexts/ioc/IocContext';
import { Types } from '../../../../ioc/types';

import { checkTokenInParams, getRulesFromUrl, getTokenDecodedFromUrl, setCookiesAccessToken, setCookiesRefreshToken, validateUrlParameters } from './utilsLoginContext';
import { ILoginFormValues } from '../form/interface';

interface ILoginContext {
  loginContext: {
    fetchLogin: (values) => void;
  }
}
interface ILoginProvider {
  children?: React.ReactNode;
}

const LoginContext = createContext<ILoginContext>(undefined);
const LoginProvider: React.FC<ILoginProvider> = ({ children }) => {

  const { snackbar } = useDialogAlert();
  const navigate = useContextualNavigate();

  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(Types.User.IAuthService);

  const urlString = window.location.href;
  const paramString = urlString?.split("?")?.[1];
  const queryString = new URLSearchParams(paramString);

  const fetchLogin = async (values: ILoginFormValues, isTokenUrl?: boolean) => {
    try {
      const { redirectURL, rules } = validateUrlParameters(queryString);
      values.rules = rules;

      const loginResponse: IAuthLoginResponse = await authService.authLogin(values);

      const {
        AccessToken = null,
        RefreshToken = null,
        state = null,
        extendedPassword = null,
        meta: { custom_attributes: { userStatus = null } = {} } = {}
      } = loginResponse || {};

      switch (state) {
        case AuthState.TOO_MANY_ACCESSES:
          throw new Error("Você atingiu o limite de tentativas, tente novamente em 10 segundos");

        case AuthState.FAILED_SENT:
          throw new Error("Tivemos um problema ao enviar o link de verificação para seu email");

        case AuthState.SENT:
          return navigateToSecurityAuth(values);

        case AuthState.FIRST_ACCESS:
        case AuthState.FORCE_CHANGE_PASSWORD:
          return navigateToFirstAccess(values, extendedPassword);

        default:
          if (userStatus !== UserStatus.CONFIRMED) {
            return navigate(`${routesConfig.NEW_PASSWORD()}`);
          }
      }

      console.info("successfully Logged In.");
      setCookiesAccessToken(AccessToken);
      setCookiesRefreshToken(RefreshToken);

      document.location.href = redirectURL; // NOTE: A url precisa receber o protocolo http para poder funcionar -> https://example.com
    } catch (error) {
      if (error as AppError) {
        isTokenUrl ?
          snackbar({
            message: `O token da sua URL possui uma senha invalida, voce deve recuperar sua senha *** ${error?.message}`,
            variant: "error",
          })
          : snackbar({
            message: error?.message,
            variant: "error",
          });
      }
    }
  }

  const navigateToSecurityAuth = (values: ILoginFormValues) => {
    navigate(routesConfig.SECURITY_AUTH, {
      state: { email: values.email, password: values.password }
    });
  };


  const navigateToFirstAccess = (values: ILoginFormValues, extendedPassword: string | null) => {
    navigate(`${routesConfig.UPDATE_PASSWORD_FIRST_ACCESS}`, {
      state: {
        password: extendedPassword || values.password,
        email: values.email,
        migration: Boolean(extendedPassword),
      },
    });
  };


  const fetchAutoLogin = async () => {
    if (!checkTokenInParams()) return;
    const decodedToken = getTokenDecodedFromUrl();
    const [email, password] = decodedToken.split(' ');
    const rules = getRulesFromUrl();

    const valuesParams: ILoginFormValues = {
      email,
      password,
      rules
    }

    await fetchLogin(valuesParams, true);
  }

  useEffect(() => {
    fetchAutoLogin();
    messageEmptyUrlParams(urlString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlString]);

  const messageEmptyUrlParams = (fullUrl: string) => {
    const defaultUrlExample = "Exemplo de como deveria ser: https://authcog.grupoatem.com.br/#/login?url=https://portal.grupoatem.com.br&rules=portal_cliente";
    const queryParams = new URLSearchParams(fullUrl.split('?')[1]);

    // Verificar a presença dos parâmetros 'url' e 'rules'
    const hasUrlParam = queryParams.has('url');
    const hasRulesParam = queryParams.has('rules');

    if (!hasUrlParam && !hasRulesParam) {
      // Cenário 1: falta dos dois parâmetros - 'url' e 'rules'
      snackbar({
        message: `Ausência dos parâmetros 'url' e 'rules' na URL. *** URL ATUAL: '${fullUrl}' *** ${defaultUrlExample}`,
        variant: "error",
      });
    } else if (hasUrlParam && !hasRulesParam) {
      // Cenário 2: contém 'url' mas falta o parâmetro 'rules'
      snackbar({
        message: `Ausência do parâmetro 'rules' na URL. *** URL ATUAL: '${fullUrl}' *** ${defaultUrlExample}`,
        variant: "error",
      });
    } else if (!hasUrlParam && hasRulesParam) {
      // Cenário 3: contém 'rules' mas falta o parâmetro 'url'
      snackbar({
        message: `Ausência do parâmetro 'url' na URL. *** URL ATUAL: '${fullUrl}' *** ${defaultUrlExample}`,
        variant: "error",
      });
    }
  };

  const loginContext = {
    fetchLogin,
  }

  return (
    <LoginContext.Provider value={{ loginContext }}>
      {children}
    </LoginContext.Provider>
  );
}

const useLoginContext = () => {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error('usePrice deve ser utilizado dentro de um LoginProvider');
  }
  return context;
};

export { LoginProvider, useLoginContext };